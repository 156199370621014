@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700');

$accent: #F60;

html, body, #root{
  height:100%;
  margin:0;
  padding:0
}

body {
  background: #e4edf2;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: 'Roboto Condensed', sans-serif;
}

.main-container {
  padding: 0;
  height: 100%;
  display: flex;
}

.main {
  align-self: center;  
  margin: auto;
  background-color: #fff;
  box-shadow: 0px 12px 46px -15px rgba(0,0,0,0.35);
  padding: 0px;
}

@media (max-width: 991.98px) {
  .main-container {
    display: block;
  }
}

@media (max-width: 575.98px) {
  .main {
    box-shadow: none;
  }
}

@keyframes shake {
  0%  { transform: rotate(0deg); }
  25% { transform: rotate(-10deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(10deg); }
  100% { transform: rotate(0deg); }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: scale(1);
  }
}

.accent-bg {
  background: $accent;
}

.network-name {
  text-transform: capitalize;
  position: relative;
  color: white;
  top: -5px;

  .fal {
    font-size: 11px;
    position: relative;
    top: -1px;    
  }
}


.brand {
  display: flex;
  height: 100%;
  color: white;

  h1 {
    margin: 10px 0px 0px 0px;
    font-weight: 300;
  }


  .version {
    font-size: 12px;
    position: relative;
    top: -8px;
  }

}

.form-group {
  margin-bottom: 5px;

  &.is-invalid {
    input, input:focus {
      border-bottom: 2px solid red;

      & + .input-group-append .input-group-text {
        border-bottom: 2px solid red !important;
        color: red;
      }
    }
  }
}

.input-group {
  margin-top: 35px;

  .input-group-text {
    background: none !important;
    border: none !important;
    border-bottom: solid 2px #e8ebed !important;
    border-radius: 0 !important;
    cursor: default;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: gray;
  }

  input:focus + .input-group-append .input-group-text {
    border-bottom: 2px solid #777 !important;
  }
}

input.withFlyingLabel,
textarea.withFlyingLabel,
select.withFlyingLabel {
  background: none;
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid #e8ebed;
  font-size: 16px;
  padding: 6px 0px;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-bottom: 2px solid #777;
  }

  &[readonly] {
    background-color: white;
  }
}

select.withFlyingLabel {
  -webkit-appearance: none;
}
select {
  background: url('https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-down-32.png') no-repeat right !important;
  background-size:16px 17px !important;
}

label.flyingLabel {
  position: absolute;
  font-weight: bold;
  opacity: 0;
  top: -5px;
  transition: all 0.3s;
  font-size: 14px;
  
  &.visible {
    top: -16px;
    opacity: 0.85;
  }
}
.winner-notice, .finalize-notice {
  color: gray;
  font-size: 14px;
}
#createAuctionBtn, #bid, #fin, #menu-btn {
  padding: 6px 25px;

  background-color: lighten($accent, 10%);
  border-color: lighten($accent, 10%);

  &:hover {
    background-color: $accent;
  }
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
}

div.countdown {
  text-align: center;
  white-space: nowrap;

  .section {
    display: inline-block;

    span.label {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
    }
  }
  .time, .separater {
    background: #474544;
    display: inline-block;
    border-radius: 3px;
    width: 60px;
    height: 60px;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #fff;
  }

  .separater {
    background: none;
    width: 20px;
    text-align: center;
    font-weight: 700;
  }
}


.footer {
  position: absolute;
  padding: 15px 2px;
  color: gray;
  font-size: 14px;
  a {
    color: gray;
    &:hover {
      text-decoration: none;
      color: black;
    }
  }
  .row > div {
    margin-bottom: 6px;
  }
}
@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.auction-placeholder {
  height: 400px;

  &:after {
    content: "";
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background-image: linear-gradient(
      -45deg, 
      rgba(255, 255, 255, .1) 25%, 
      transparent 25%, 
      transparent 50%, 
      rgba(255, 255, 255, .1) 50%, 
      rgba(255, 255, 255, .1) 75%, 
      transparent 75%, 
      transparent
    );
    z-index: 1;
    background-size: 50px 50px;
    animation: move 2s linear infinite;
    overflow: hidden;
  }
}
.fav-star {
  display: inline-block;
  margin: 0px 8px;
  cursor: pointer;

  .icon {
    position: relative;
    top: -10px;
    font-size: 20px;

    &.fa-star {
      background-color: #f06543;
      background-image: linear-gradient(315deg, darken(yellow, 10%) 0%, yellow 74%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.fa.golden {
  background-color: #f06543;
  background-image: linear-gradient(315deg, darken(yellow, 10%) 0%, darken(yellow, 8%) 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.auction-header {
  text-align: center;
  color: white;
  padding-top: 30px;
  padding-bottom: 30px;

  #auction-title {
    text-transform: uppercase;
    font-size: 3em;
    margin-bottom: 10px;
    display: inline-block;
  }

  .highest-bid {
    background: #fefefe;
    border-radius: 3px;
    text-align: center;
    color: black;
    text-transform: uppercase;
    padding: 5px;
    margin-bottom: 20px;

    h3 {
      margin: 25px auto -5px auto;
      letter-spacing: -1px;
      font-size: 35px;
    }

    .highest-bid-indicator {
      font-size: 14px;
      position: absolute;
      top: 1px;
      right: 20px;
      color: $accent;
      animation: shake 0.15s infinite;
      transition: all 0.3s;

      &.hidden {
        opacity: 0;
      }
    }

    .bidder {
      font-size: 14px;
      margin-bottom: 20px;
      color: #333;

      .you {
        border-radius: 2px;
        background: $accent;
        border: 0;
        margin-left: 5px;
        position: relative;
        top: -1px;

        &.hidden {
          display: none;
        }
      }
    }

  }

  .hb-label {
    position: absolute;
    height: auto;
    background: #474544;
    border-radius: 3px 3px 0px 0px;
    color: white;
    padding: 4px 0px;
    z-index: 1;
    font-size: 13px;
    transform: rotate(-90.0deg);
    transform-origin: left top 0;
  }

  a:hover {
    text-decoration: none;
  }
}

.bid-container {
  border-top: 2px solid $accent;
  margin-top: 10px;
  padding-top: 10px;
}

div[class^="sc"] {
  text-transform: uppercase;
}

.auctions-list {
  min-height: 300px;

  .addr {
    .fa {
      position: relative;
      top: 0px;
      font-size: 12px;
    }
  }
  a {
    text-decoration: none;
    position: relative;
    top: 1px;

    &.to-auction {
      color: $accent;
    }
  }


  .desc {
    position: absolute;
    right: 0;
    & a {
      color: $accent;
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
  }
}

.metamask-modal {
  a {
    color: $accent;
  }

  .modal-footer {
    button {
      padding: 6px 25px;

      background-color: lighten($accent, 10%);
      border-color: lighten($accent, 10%);

      &:hover {
        background-color: $accent;
        border-color: $accent;
      }
      &:focus {
        outline: 0;
        box-shadow: none !important;
      }
    }
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#date-picker-container {
  width: 100%;
  position: relative; }

#datepicker {
  position: absolute;
  background-color: white;
  z-index: 999;
  border-radius: 0;
  display: none;
  width: inherit; }

#datepicker.visible {
  display: block; }

#datepicker .options button {
  background: none;
  -webkit-appearance: none;
  border-color: #F60;
  color: #F60;
  border-radius: 0;
  transition: all 0.3s; }

#datepicker button:focus {
  outline: 0; }

#datepicker .options button.is-active {
  background: #F60;
  color: #EEE; }

#datepicker div.toolbar button {
  border-radius: 0;
  color: gray;
  border: 0;
  background: none;
  font-size: 14px;
  transition: all 0.3s; }

#datepicker div.toolbar button:hover {
  color: black; }

#datepicker .current-date {
  color: #F60;
  text-transform: uppercase; }

#datepicker table thead tr td {
  color: #F60; }

#datepicker table .current-day {
  color: white;
  background-color: #ff8533;
  border-radius: 2px; }

#datepicker button.btn-save {
  border-radius: 0;
  background: #F60;
  color: #EEE; }

#datepicker .m-calendar tbody td:hover {
  background: #F60;
  border-color: black;
  color: white !important; }

#datepicker .showtime .time {
  background: #F60; }

#datepicker .sliders .u-slider-time .handle:after {
  border-color: #F60; }

#datepicker .sliders .u-slider-time .value {
  background: #F60; }

#datepicker .sliders .time-text,
#datepicker .showtime .separater {
  color: #F60;
  text-transform: uppercase; }

#datepicker .m-calendar td {
  border: 0; }

#datepicker .m-calendar .next-month, .m-calendar .prev-month {
  color: #aaa; }

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0; }

body {
  background: #e4edf2;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: 'Roboto Condensed', sans-serif; }

.main-container {
  padding: 0;
  height: 100%;
  display: -webkit-flex;
  display: flex; }

.main {
  -webkit-align-self: center;
          align-self: center;
  margin: auto;
  background-color: #fff;
  box-shadow: 0px 12px 46px -15px rgba(0, 0, 0, 0.35);
  padding: 0px; }

@media (max-width: 991.98px) {
  .main-container {
    display: block; } }

@media (max-width: 575.98px) {
  .main {
    box-shadow: none; } }

@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg); }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  75% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes shake {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg); }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  75% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@-webkit-keyframes heartbeat {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes heartbeat {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.accent-bg {
  background: #F60; }

.network-name {
  text-transform: capitalize;
  position: relative;
  color: white;
  top: -5px; }
  .network-name .fal {
    font-size: 11px;
    position: relative;
    top: -1px; }

.brand {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  color: white; }
  .brand h1 {
    margin: 10px 0px 0px 0px;
    font-weight: 300; }
  .brand .version {
    font-size: 12px;
    position: relative;
    top: -8px; }

.form-group {
  margin-bottom: 5px; }
  .form-group.is-invalid input, .form-group.is-invalid input:focus {
    border-bottom: 2px solid red; }
    .form-group.is-invalid input + .input-group-append .input-group-text, .form-group.is-invalid input:focus + .input-group-append .input-group-text {
      border-bottom: 2px solid red !important;
      color: red; }

.input-group {
  margin-top: 35px; }
  .input-group .input-group-text {
    background: none !important;
    border: none !important;
    border-bottom: solid 2px #e8ebed !important;
    border-radius: 0 !important;
    cursor: default;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: gray; }
  .input-group input:focus + .input-group-append .input-group-text {
    border-bottom: 2px solid #777 !important; }

input.withFlyingLabel,
textarea.withFlyingLabel,
select.withFlyingLabel {
  background: none;
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid #e8ebed;
  font-size: 16px;
  padding: 6px 0px; }
  input.withFlyingLabel:focus,
  textarea.withFlyingLabel:focus,
  select.withFlyingLabel:focus {
    outline: 0;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-bottom: 2px solid #777; }
  input.withFlyingLabel[readonly],
  textarea.withFlyingLabel[readonly],
  select.withFlyingLabel[readonly] {
    background-color: white; }

select.withFlyingLabel {
  -webkit-appearance: none; }

select {
  background: url("https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-down-32.png") no-repeat right !important;
  background-size: 16px 17px !important; }

label.flyingLabel {
  position: absolute;
  font-weight: bold;
  opacity: 0;
  top: -5px;
  transition: all 0.3s;
  font-size: 14px; }
  label.flyingLabel.visible {
    top: -16px;
    opacity: 0.85; }

.winner-notice, .finalize-notice {
  color: gray;
  font-size: 14px; }

#createAuctionBtn, #bid, #fin, #menu-btn {
  padding: 6px 25px;
  background-color: #ff8533;
  border-color: #ff8533; }
  #createAuctionBtn:hover, #bid:hover, #fin:hover, #menu-btn:hover {
    background-color: #F60; }
  #createAuctionBtn:focus, #bid:focus, #fin:focus, #menu-btn:focus {
    outline: 0;
    box-shadow: none !important; }

div.countdown {
  text-align: center;
  white-space: nowrap; }
  div.countdown .section {
    display: inline-block; }
    div.countdown .section span.label {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700; }
  div.countdown .time, div.countdown .separater {
    background: #474544;
    display: inline-block;
    border-radius: 3px;
    width: 60px;
    height: 60px;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #fff; }
  div.countdown .separater {
    background: none;
    width: 20px;
    text-align: center;
    font-weight: 700; }

.footer {
  position: absolute;
  padding: 15px 2px;
  color: gray;
  font-size: 14px; }
  .footer a {
    color: gray; }
    .footer a:hover {
      text-decoration: none;
      color: black; }
  .footer .row > div {
    margin-bottom: 6px; }

@-webkit-keyframes move {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 50px 50px; } }

@keyframes move {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 50px 50px; } }

.auction-placeholder {
  height: 400px; }
  .auction-placeholder:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
    z-index: 1;
    background-size: 50px 50px;
    -webkit-animation: move 2s linear infinite;
            animation: move 2s linear infinite;
    overflow: hidden; }

.fav-star {
  display: inline-block;
  margin: 0px 8px;
  cursor: pointer; }
  .fav-star .icon {
    position: relative;
    top: -10px;
    font-size: 20px; }
    .fav-star .icon.fa-star {
      background-color: #f06543;
      background-image: linear-gradient(315deg, #cccc00 0%, yellow 74%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }

.fa.golden {
  background-color: #f06543;
  background-image: linear-gradient(315deg, #cccc00 0%, #d6d600 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.auction-header {
  text-align: center;
  color: white;
  padding-top: 30px;
  padding-bottom: 30px; }
  .auction-header #auction-title {
    text-transform: uppercase;
    font-size: 3em;
    margin-bottom: 10px;
    display: inline-block; }
  .auction-header .highest-bid {
    background: #fefefe;
    border-radius: 3px;
    text-align: center;
    color: black;
    text-transform: uppercase;
    padding: 5px;
    margin-bottom: 20px; }
    .auction-header .highest-bid h3 {
      margin: 25px auto -5px auto;
      letter-spacing: -1px;
      font-size: 35px; }
    .auction-header .highest-bid .highest-bid-indicator {
      font-size: 14px;
      position: absolute;
      top: 1px;
      right: 20px;
      color: #F60;
      -webkit-animation: shake 0.15s infinite;
              animation: shake 0.15s infinite;
      transition: all 0.3s; }
      .auction-header .highest-bid .highest-bid-indicator.hidden {
        opacity: 0; }
    .auction-header .highest-bid .bidder {
      font-size: 14px;
      margin-bottom: 20px;
      color: #333; }
      .auction-header .highest-bid .bidder .you {
        border-radius: 2px;
        background: #F60;
        border: 0;
        margin-left: 5px;
        position: relative;
        top: -1px; }
        .auction-header .highest-bid .bidder .you.hidden {
          display: none; }
  .auction-header .hb-label {
    position: absolute;
    height: auto;
    background: #474544;
    border-radius: 3px 3px 0px 0px;
    color: white;
    padding: 4px 0px;
    z-index: 1;
    font-size: 13px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: left top 0;
            transform-origin: left top 0; }
  .auction-header a:hover {
    text-decoration: none; }

.bid-container {
  border-top: 2px solid #F60;
  margin-top: 10px;
  padding-top: 10px; }

div[class^="sc"] {
  text-transform: uppercase; }

.auctions-list {
  min-height: 300px; }
  .auctions-list .addr .fa {
    position: relative;
    top: 0px;
    font-size: 12px; }
  .auctions-list a {
    text-decoration: none;
    position: relative;
    top: 1px; }
    .auctions-list a.to-auction {
      color: #F60; }
  .auctions-list .desc {
    position: absolute;
    right: 0; }
    .auctions-list .desc a {
      color: #F60;
      cursor: pointer; }
      .auctions-list .desc a:hover {
        color: red; }

.metamask-modal a {
  color: #F60; }

.metamask-modal .modal-footer button {
  padding: 6px 25px;
  background-color: #ff8533;
  border-color: #ff8533; }
  .metamask-modal .modal-footer button:hover {
    background-color: #F60;
    border-color: #F60; }
  .metamask-modal .modal-footer button:focus {
    outline: 0;
    box-shadow: none !important; }


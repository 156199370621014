$accent: #F60;

#date-picker-container {
  width: 100%;
  position: relative;
}
#datepicker {
  position: absolute;
  background-color: white;
  z-index: 999;
  border-radius: 0;
  display: none;
  width: inherit;
}
#datepicker.visible {
  display: block;
}
#datepicker .options button {
  background: none;
  -webkit-appearance: none;
  border-color: $accent;
  color: $accent;
  border-radius: 0;
  transition: all 0.3s;
}
#datepicker button:focus {
  outline: 0;
}
#datepicker .options button.is-active {
  background: $accent;
  color: #EEE;
}
#datepicker div.toolbar button {
  border-radius: 0;
  color: gray;
  border: 0;
  background: none;
  font-size: 14px;
  transition: all 0.3s;
}
#datepicker div.toolbar button:hover {
  color: black;
}
#datepicker .current-date {
  color: $accent;
  text-transform: uppercase;
}
#datepicker table thead tr td {
  color: $accent;
}
#datepicker table .current-day {
  color: white;
  background-color: lighten($accent, 10%);
  border-radius: 2px;
}
#datepicker button.btn-save {
  border-radius: 0;
  background: $accent;
  color: #EEE;
}
#datepicker .m-calendar tbody td:hover {
  background: $accent;
  border-color: black;
  color: white !important;
}

#datepicker .showtime .time{
  background: $accent;
}
#datepicker .sliders .u-slider-time .handle:after {
  border-color: $accent;
}
#datepicker .sliders .u-slider-time .value {
  background: $accent;
}
#datepicker .sliders .time-text,
#datepicker .showtime .separater{
  color: $accent;
  text-transform: uppercase;
}

#datepicker .m-calendar td {
  border: 0;
}

#datepicker .m-calendar .next-month, .m-calendar .prev-month {
  color: #aaa;
}
